import { ACCOUNT_STATUS } from '@pw/consts/account';
import { UNIT_FORMAT_TYPES } from '@pw/consts/company';
import { UNIT, UNITS } from '@pw/consts/units';
import { createCompanyThunk, updateCompanyThunk } from '@pw/redux/thunks/company';
import { refetchFacilitiesThunk, upsertFacilityThunk } from '@pw/redux/thunks/facilities';
import { inviteOwnerThunk, refetchOwnersThunk, upsertOwnerThunk } from '@pw/redux/thunks/owners';
import { connectWithPartnerThunk, refetchPartnersThunk, upsertPartnerThunk } from '@pw/redux/thunks/partners';
import { recoverThunk, registerUserThunk } from '@pw/redux/thunks/register';
import { refetchRolesThunk, upsertRoleThunk } from '@pw/redux/thunks/roles';
import {
  refetchSensors,
  upsertGatewayThunk,
  upsertSensorThunk,
} from '@pw/redux/thunks/sensors';
import {
  signinUserThunk,
  signinWithGoogleThunk,
  signinWithPasskeyThunk,
} from '@pw/redux/thunks/signin';
import {
  cloneSKUThunk,
  refetchSKUsThunk,
  updateSKUsThunk,
  upsertSKUThunk,
} from '@pw/redux/thunks/sku';
import { refetchTeamsThunk, upsertTeamThunk } from '@pw/redux/thunks/teams';
import {
  refetchUsersThunk,
  refreshTokenThunk,
  removeUserThunk,
  switchCompanyThunk,
  updateProfileThunk,
  upsertUserThunk,
} from '@pw/redux/thunks/user';
import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';
import { SLICE } from './consts';
import {
  refetchPoliciesThunk,
  upsertPolicyThunk,
} from '@pw/redux/thunks/policy';

const processPayload = (state, payload) => {
  const account_id = payload.account_id;
  const account_name = payload.account_name;
  const account_identity = payload.account_identity;
  const account_username = payload.account_username;
  const account_status = payload.account_status ?? ACCOUNT_STATUS.PENDING;
  const account_email = payload.account_email;
  const account_perms = payload.account_perms;
  const account_location = JSON.parse(payload.account_location ?? '{}');
  const unit_format = payload.imperial_units
    ? UNIT_FORMAT_TYPES.Imperial
    : UNIT_FORMAT_TYPES.Metric;
  const account_last_access = payload.account_last_access;
  const platform = payload.platform;
  const chain = payload.chain;

  // Get the company details
  const companies = payload.companies ?? [];
  // Get the current company
  const company =
    payload.company ??
    companies.find((c) => c.company_id === payload?.company_id);
  // Grab the units
  const imperial = unit_format === UNIT_FORMAT_TYPES.Imperial;
  // const units = payload?.base_units ?? {};
  const units = {
    [UNIT.LIQ]: imperial ? UNITS.gallons : UNITS.liters,
    [UNIT.MAS]: imperial ? UNITS.pounds : UNITS.kilograms,
    [UNIT.ALC]: imperial ? UNITS.proof : UNITS.abv,
    [UNIT.TMP]: imperial ? UNITS.fahrenheit : UNITS.celsius,
    [UNIT.TIM]: UNITS.days,
    [UNIT.BL]: imperial ? UNITS.gallons : UNITS.liters,
    [UNIT.LA]: imperial ? UNITS.proof_gallons : UNITS.liters,
  };

  Object.entries(JSON.parse(payload?.base_units) ?? {}).forEach(
    ([unit, value]) => {
      units[unit] = value;
    },
  );
  const token_active = payload.nbf;

  return {
    account_id,
    account_name,
    account_identity,
    account_username,
    account_status,
    account_email,
    account_perms,
    account_location,
    unit_format,
    account_last_access,
    platform,
    chain,
    companies,
    company,
    units,
    token_active,
    partnerConnectLink: state?.partnerConnectLink ?? {
      ...initialState.partnerConnectLink,
    },
  };
};

const initialState = {
  account_id: '',
  account_name: '',
  account_identity: '',
  account_username: '',
  account_status: ACCOUNT_STATUS.PENDING,
  account_email: '',
  account_perms: '0b0',
  account_location: {},
  unit_format: UNIT_FORMAT_TYPES.Metric,
  account_last_access: 0,
  chain: null,
  platform: null,
  // All companies
  companies: [],
  // Current company
  company: null,
  token_active: 0,
  // Current units
  units: {
    [UNIT.LIQ]: UNITS.liters,
    [UNIT.MAS]: UNITS.kilograms,
    [UNIT.ALC]: UNITS.abv,
    [UNIT.TMP]: UNITS.celsius,
    [UNIT.TIM]: UNITS.days,
    [UNIT.BL]: UNITS.liters,
    [UNIT.LA]: UNITS.liters,
  },
  partnerConnectLink: {
    link: null,
    ident: null,
  },
};

export const slice = createSlice({
  name: SLICE,
  initialState,
  reducers: {
    setBaseUnits(state, { payload }) {
      state.units = payload;
    },
    setPartnerConnectLink(state, { payload }) {
      state.partnerConnectLink.link = payload.link;
      state.partnerConnectLink.ident = payload.ident;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(updateProfileThunk.fulfilled, (state, { payload }) => {
      console.log('updateProfileThunk.fulfilled', payload);
      return processPayload(state, payload);
    });
    builder.addCase(recoverThunk.fulfilled, (state, { payload }) => {
      console.log('recoverThunk.fulfilled', payload);
      return processPayload(state, payload);
    });
    builder.addCase(registerUserThunk.fulfilled, (state, { payload }) => {
      console.log('registerUserThunk.fulfilled', payload);
      return processPayload(state, payload);
    });
    builder.addCase(signinUserThunk.fulfilled, (state, { payload }) => {
      console.log('signinUser.fulfilled', payload);
      return processPayload(state, payload);
    });
    builder.addCase(signinWithPasskeyThunk.fulfilled, (state, { payload }) => {
      console.log('signinWithPasskeyThunk.fulfilled', payload);
      return processPayload(state, payload);
    });
    builder.addCase(signinWithGoogleThunk.fulfilled, (state, { payload }) => {
      console.log('signinWithGoogle.fulfilled', payload);
      return processPayload(state, payload);
    });

    builder.addCase(refreshTokenThunk.fulfilled, (state, { payload }) => {
      console.log('refreshToken.fulfilled', payload);
      return processPayload(state, payload);
    });

    builder.addCase(switchCompanyThunk.fulfilled, (state, { payload }) => {
      console.log('switchCompany.fulfilled', payload);
      return processPayload(state, payload);
    });

    builder.addCase(createCompanyThunk.fulfilled, (state, { payload }) => {
      console.log('createCompany.fulfilled', payload);
      return processPayload(state, payload);
    });

    builder.addCase(updateCompanyThunk.fulfilled, (state, { payload }) => {
      console.log('updateCompany.fulfilled', payload);

      const index = state.companies.findIndex(
        (c) => c.company_id === payload.company_id,
      );
      if (index >= 0) {
        const clone = [...state.companies];
        clone[index] = payload;
        state.companies = clone;
      } else {
        state.companies.push(payload);
      }
      state.company = { ...state.company, ...payload };
    });

    builder.addCase(upsertFacilityThunk.fulfilled, (state, { payload }) => {
      console.log('upsertFacility.fulfilled', payload);
      // Get the facility and update the company
      const facility = payload;
      const facilities = state.company.facilities ?? {};

      const id =
        `${facility.facility_type}/${facility.facility_name}`.toLowerCase();
      facilities[id] = facility;

      state.company.facilities = facilities;
    });

    builder.addCase(refetchFacilitiesThunk.fulfilled, (state, { payload }) => {
      console.log('refetchFacilitiesThunk.fulfilled', payload);
      state.company.facilities = payload;
    });

    builder.addCase(upsertPartnerThunk.fulfilled, (state, { payload }) => {
      console.log('upsertPartnerThunk.fulfilled', payload);
      const partner = payload;
      const partners = state.company.partners ?? [];

      const index = partners.findIndex((p) => p.path === partner.path);
      if (index >= 0) {
        const clone = [...partners];
        clone[index] = partner;
        state.company.partners = clone;
      } else {
        if (!state?.company) state.company = {};
        if (!state?.company?.partners) state.company.partners = [];
        state.company.partners.push(partner);
      }

      // if (state?.partnerConnectLink?.link) {
      //   // get params from url
      //   const url = new URL(`${origin}${state.partnerConnectLink.link}`);
      //   const company_id = url.searchParams.get('qq');
      //   const partner_id = url.searchParams.get('q');
      //   // check exists and check if created partner is same as in url
      //   if (
      //     company_id &&
      //     partner_id &&
      //     company_id === partner.company_id &&
      //     partner_id === partner.partner_id
      //   ) {
      //     // remove the state
      //     state.partnerConnectLink = { ...initialState.partnerConnectLink };
      //   }
      // }
    });

    builder.addCase(refetchPartnersThunk.fulfilled, (state, { payload }) => {
      console.log('refetchPartnersThunk.fulfilled', payload);
      state.company.partners = payload.items;
    });

    builder.addCase(connectWithPartnerThunk.fulfilled, (state, { payload }) => {
      console.log('connectWithPartnerThunk.fulfilled', payload);
      const partner = payload;
      const partners = state.company.partners ?? [];

      const index = partners.findIndex((p) => p.path === partner.path);
      if (index >= 0) {
        const clone = [...partners];
        clone[index] = partner;
        state.company.partners = clone;
      } else {
        if(!state?.company) state.company = {}
        if(!state?.company?.partners) state.company.partners = []
        state.company.partners.push(partner);
      }

      // if (state?.partnerConnectLink?.link) {
      //   // get params from url
      //   const url = new URL(`${origin}${state.partnerConnectLink.link}`);
      //   const company_id = url.searchParams.get('qq');
      //   const partner_id = url.searchParams.get('q');
      //   // check exists and check if created partner is same as in url
      //   if (
      //     company_id &&
      //     partner_id &&
      //     company_id === partner.company_id &&
      //     partner_id === partner.partner_id
      //   ) {
      //     // remove the state
      //     state.partnerConnectLink = { ...initialState.partnerConnectLink };
      //   }
      // }
    });

    builder.addCase(upsertOwnerThunk.fulfilled, (state, { payload }) => {
      console.log('upsertOwnerThunk.fulfilled', payload);
      const owner = payload;
      const owners = state.company.owners ?? [];

      const index = owners.findIndex((p) => p.path === owner.path);
      if (index >= 0) {
        const clone = [...owners];
        clone[index] = owner;
        state.company.owners = clone;
      } else {
        //if (state?.company?.owners) state.company = {};
        if (!state?.company?.owners) state.company.owners = [];
        state.company.owners.push(owner);
      }
    });

    builder.addCase(inviteOwnerThunk.fulfilled, (state, { payload }) => {
      /** update after invite */
      console.log('inviteOwnerThunk.fulfilled', payload);
      const owner = payload;
      const owners = state.company.owners ?? [];

      const index = owners.findIndex((p) => p.path === owner.path);
      if (index >= 0) {
        const clone = [...owners];
        clone[index] = owner;
        state.company.owners = clone;
      } else {
        state.company.owners.push(owner);
      }
    });

    builder.addCase(refetchOwnersThunk.fulfilled, (state, { payload }) => {
      console.log('refetchOwnersThunk.fulfilled', payload);
      state.company.owners = payload.items;
    });

    builder.addCase(upsertUserThunk.fulfilled, (state, { payload }) => {
      console.log('upsertUserThunk.fulfilled', payload);
      const user = payload;
      const users = state.company.users ?? [];

      const index = users.findIndex((p) => p.path === user.path);
      if (index >= 0) {
        const clone = [...users];
        clone[index] = user;
        state.company.users = clone;
      } else {
        state.company.users.push(user);
      }
    });

    builder.addCase(refetchUsersThunk.fulfilled, (state, { payload }) => {
      console.log('refetchUsersThunk.fulfilled', payload);
      state.company.users = payload;
    });

    builder.addCase(removeUserThunk.fulfilled, (state, { payload }) => {
      console.log('removeUserThunk.fulfilled', payload);
      state.company.users = state.company.users.filter(
        (p) => p.account_id !== payload.account_id,
      );
    });

    builder.addCase(upsertRoleThunk.fulfilled, (state, { payload }) => {
      console.log('upsertRoleThunk.fulfilled', payload);
      state.company.roles = payload;
    });

    builder.addCase(refetchRolesThunk.fulfilled, (state, { payload }) => {
      console.log('refetchRolesThunk.fulfilled', payload);
      state.company.roles = payload;
    });

    builder.addCase(upsertTeamThunk.fulfilled, (state, { payload }) => {
      console.log('upsertTeamThunk.fulfilled', payload);
      state.company.teams = payload;
    });

    builder.addCase(refetchTeamsThunk.fulfilled, (state, { payload }) => {
      console.log('refetchTeamsThunk.fulfilled', payload);
      state.company.teams = payload;
    });

    builder.addCase(cloneSKUThunk.fulfilled, (state, { payload }) => {
      console.log('cloneSKUThunk.fulfilled', payload);
      state.company.skus = [...state.company.skus, payload];
    });

    builder.addCase(upsertSKUThunk.fulfilled, (state, { payload }) => {
      console.log('upsertSKUThunk.fulfilled', payload);
      const sku = payload;
      const skus = state.company.skus ?? [];

      const index = skus.findIndex((p) => p.path === sku.path);
      if (index >= 0) {
        const clone = [...skus];
        clone[index] = sku;
        state.company.skus = clone;
      } else {
        state.company.skus = [...(state.company.skus ?? []), sku];
      }
    });

    builder.addCase(refetchSKUsThunk.fulfilled, (state, { payload }) => {
      console.log('refetchSKUsThunk.fulfilled', payload);
      state.company.skus = payload;
    });

    builder.addCase(updateSKUsThunk.fulfilled, (state, { payload }) => {
      console.log('updateSKUsThunk.fulfilled', payload);
      state.company.skus = payload;
    });

    builder.addCase(refetchPoliciesThunk.fulfilled, (state, { payload }) => {
      console.log('refetchPoliciesThunk.fulfilled', payload);
      state.company.policies = payload;
    });

    builder.addCase(upsertPolicyThunk.fulfilled, (state, { payload }) => {
      console.log('upsertPolicyThunk.fulfilled', payload);
      const policy = payload;
      const policies = state.company.policies ?? [];

      const index = policies.findIndex((p) => p.path === policy.path);
      if (index >= 0) {
        const clone = [...policies];
        clone[index] = policy;
        state.company.policies = clone;
      } else {
        state.company.policies = [...(state.company.policies ?? []), policy];
      }
    });

    builder.addCase(refetchSensors.fulfilled, (state, { payload }) => {
      console.log('refetchSensors.fulfilled', payload);
      state.company.sensors = payload?.items ?? [];
    });

    builder.addCase(upsertGatewayThunk.fulfilled, (state, { payload }) => {
      console.log('upsertGatewayThunk.fulfilled', payload);
      // state.company.skus = payload;
      const gateway = payload;

      const gateways = state.company.sensors ?? [];

      const index = gateways.find((s) => s.path === gateway.path);
      if (index >= 0) {
        const clone = [...gateways];
        clone[index] = gateway;
        state.company.sensors = clone;
      } else {
        state.company.sensors = [...gateways, gateway];
      }
    });

    builder.addCase(upsertSensorThunk.fulfilled, (state, { payload }) => {
      console.log('upsertSensorThunk.fulfilled', payload);
      // state.company.skus = payload;
      const sensor = payload;

      const gateways = state.company.sensors ?? [];

      const gateway = gateways.find((p) => p.path === sensor.gateway_id);
      if (gateway) {
        const sensors = gateway.sensors ?? [];
        const index = sensors.find((s) => s.path === sensor.path);
        if (index >= 0) {
          const clone = [...sensors];
          clone[index] = sensor;
          gateway.sensors = clone;
        } else {
          gateway.sensors = [...sensors, sensor];
        }
      }
    });

    builder.addCase(PURGE, () => {
      console.log('PURGE');
      window.localStorage.removeItem('auth-token');
      window.localStorage.removeItem('company-id');
      window.localStorage.removeItem('account-id');
      return { ...initialState };
    });
  },
});

export const { reducer, actions } = slice;
export const { setBaseUnits, setPartnerConnectLink } = actions;
