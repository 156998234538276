import { Box } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import {
	FormikCheckBox,
	FormikDatePicker,
	FormikTextField,
} from '@pw/components/Forms/FormikForm';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import { H4 } from '@pw/components/Typography';
import FormikContext from '@pw/context/FormikContext';
import { FormikProvider } from '@pw/providers/FormikProvider';
import { mt2 } from '@pw/styles/margins';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import RevertMessage from '../Forms/RevertMessage';
import useAssetRevertHook from '@pw/utilities/hooks/logic/useAssetRevertHook';

function StorageChargeCodePropertiesImpl({ disable = false, entity = null }) {
	const { values, setFieldValue, readonly } = useContext(FormikContext);

	const [historyExist, isSame, history] = useAssetRevertHook(
		entity,
		(e, e2) => e?.charge_code?.code === e2?.charge_code?.code,
	);

	useEffect(() => {
		if (!values?.enable) {
			setFieldValue('charge_code', {
				...values,
				enable: false,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values?.enable]);

	const handleRevert = useCallback(() => {
		setFieldValue('code', history.charge_code?.code);
		setFieldValue('start_date', history.charge_code?.start_date);
		setFieldValue('end_date', history.charge_code?.end_date);
		setFieldValue('enable', history.charge_code?.enable);
	}, [history, setFieldValue]);

	const onUndo = useCallback(() => {
		setFieldValue('code', entity.charge_code?.code);
		setFieldValue('start_date', entity.charge_code?.start_date);
		setFieldValue('end_date', entity.charge_code?.end_date);
		setFieldValue('enable', entity.charge_code?.enable);
	}, [entity, setFieldValue]);

	const isReverted = useMemo(() => {
		return values['code'] === history?.charge_code?.code
			&& values['start_date'] === history?.charge_code?.start_date
			&& values['end_date'] === history?.charge_code?.end_date
			&& values['enable'] === history?.charge_code?.enable
	}, [history, values])

	return (
		<Box sx={{ position: 'relative' }}>
			{historyExist && !isSame && (
				<RevertMessage
					title='Storage Charge Code'
					onRevert={handleRevert}
					isReverted={isReverted}
					onUndo={onUndo}
					item={history?.charge_code}
					type="charge_code"
				/>
			)}

			<Stack>
				<FormikCheckBox
					name='enable'
					disabled={readonly || disable}
					label={<H4>Storage Charge Code</H4>}
				/>

				<Collapse in={!!values?.enable} unmountOnExit>
					<Stack spacing={3} sx={{ ...mt2 }}>
						<FlexBox>
							<FormikTextField
								label='Charge Code'
								name='code'
								fullWidth
								required
								disabled={readonly || disable}
							/>
						</FlexBox>
						<FlexBox spacing={2} alignItems='top'>
							<FormikDatePicker
								name='start_date'
								label='Start Date'
								fullWidth
								required
								disabled={readonly || disable}
							/>
							<FormikDatePicker
								name='end_date'
								label='End Date'
								fullWidth
								required
								disabled={readonly || disable}
							/>
						</FlexBox>
					</Stack>
				</Collapse>
			</Stack>
		</Box>
	);
}

export default function StorageChargeCodeProperties({ name, entity }) {
	const { readonly } = useContext(FormikContext);

	return (
		!readonly && (
			<FormikProvider path={name}>
				<StorageChargeCodePropertiesImpl entity={entity} />
			</FormikProvider>
		)
	);
}
