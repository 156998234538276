import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import AccountDisplay from '@pw/components/properties/AccountDisplay';
import ActionDisplay from '@pw/components/properties/ActionDisplay';
import { Overline } from '@pw/components/Typography';
import { COMPANY_TYPE } from '@pw/consts/company';
import { useCurrentCompany } from '@pw/redux/containers/User';

export default function SingleTimeline({
	item = { tag: 'default tag', time: null, account_name: '' },
	title = false,
}) {
	const currentCompany = useCurrentCompany();

	const text = item.tag.replace('.', ' ');

	const getStatusColor = (param) => {
		switch (param) {
			case 'A/P':
				return 'grey';
			default:
				return 'success';
		}
	};

	const getStatusStylesAndText = (param) => {
		switch (param) {
			case 'A/C':
				return {
					color: 'success',
					status: 'approved',
				};
			case 'A/U':
				return {
					color: 'info',
					status: 'updated',
				};
			case 'A/P':
				return {
					status: 'created',
				};
			default:
				return { color: 'grey', status: 'unknown' };
		}
	};
	const { color, status } = getStatusStylesAndText(text);

	return (
		<>
			{title ? (
				<TimelineItem
					style={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<TimelineSeparator sx={{ flexShrink: 0 }}>
						<TimelineDot color={getStatusColor(text)} />
						{!title && <TimelineConnector />}
					</TimelineSeparator>
					<TimelineContent
						style={{ textAlign: 'left', maxWidth: '400px', minWidth: '370px' }}
					>
						<Overline>{new Date(item.time).toLocaleString()}</Overline>
						{![COMPANY_TYPE.BROKER, COMPANY_TYPE.BRAND, COMPANY_TYPE.VIEW].includes(currentCompany.company_type) && (
							<AccountDisplay name={item.account_name} />
						)}
						<ActionDisplay color={color} label={status} />
					</TimelineContent>
				</TimelineItem>
			) : (
				<TimelineItem
					sx={{
						display: 'flex',
						position: 'relative',
						bottom: '35px',
					}}
				>
					<TimelineSeparator sx={{ flexShrink: 0 }}>
						<TimelineDot color={getStatusColor(text)} />
						<TimelineConnector />
					</TimelineSeparator>
					<TimelineContent
						style={{ textAlign: 'left', maxWidth: '400px', minWidth: '420px' }}
					>
						<Overline>{new Date(item.time).toLocaleString()}</Overline>
						{![COMPANY_TYPE.BROKER, COMPANY_TYPE.BRAND, COMPANY_TYPE.VIEW].includes(currentCompany.company_type) && (
							<AccountDisplay name={item.account_name} />
						)}
						<ActionDisplay color={color} label={status} />
					</TimelineContent>
				</TimelineItem>
			)}
		</>
	);
}
