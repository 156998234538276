import {
	AddCircle,
	CopyAllOutlined,
	Delete,
	DeleteForever,
	Done,
	Error,
	Print,
	Restore,
} from '@mui/icons-material';
import { ButtonBase } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import { AssetLog } from '@pw/components/AssetLog/AssetLogs';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import TitledButton from '@pw/components/Buttons/TitledButton';
import CameraFormFieldComponent from '@pw/components/Camera/CameraComponent';
import withSmallDialogCard from '@pw/components/Cards/SmallDialogCard';
import Confirm from '@pw/components/Confirm';
import CreatorProps from '@pw/components/CreatorProps';
import SensorWidget from '@pw/components/Dashboard/components/SensorWidget';
import DefectItem from '@pw/components/Defect/DefectItem';
import { FlexBox } from '@pw/components/Layout/FlexBox';
import AssetQRCode from '@pw/components/QRImage/AssetQRCode';
import TaskModal from '@pw/components/Tasks/TaskModal';
import { Body1, H5 } from '@pw/components/Typography';
import Regauge from '@pw/components/icons/Regauge';
import Sample from '@pw/components/icons/Sample';
import {
	ASSET_EDIT_URL,
	ASSET_STATUS,
	ASSET_TYPES,
	ASSET_TYPES_REVERSE,
	isAssetPending,
} from '@pw/consts/asset';
import {
	useAccountPermissions,
	useCompanyUsers,
	useIsAccountActive,
} from '@pw/redux/containers/User/hooks';
import { deleteAssetThunk } from '@pw/redux/thunks/asset';
import { deleteAssetPhoto, getAssetPhoto } from '@pw/services/asset.service';
import styles from '@pw/styles/content.styles';
import { toTasksCardEntity } from '@pw/utilities/format';
import useReadOnly from '@pw/utilities/hooks/logic/useReadOnly';
import printElement from '@pw/utilities/printElement';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export function AssetSummary({ entity, refresh, showControls = true }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {
		path,
		asset_id,
		asset_uid,
		asset_type,
		asset_status,
		update_on,
		properties = {},
		defect_note,
		defects = [],
		history,
	} = entity;

	const { enqueueSnackbar } = useSnackbar();

	const { canApprove = false } = useAccountPermissions();
	const { readonly } = useReadOnly(entity);
	const userIsActive = useIsAccountActive();

	const [actionKey, setActionKey] = useState(null);
	const [deleteModal, setDeleteModal] = useState(false);
	const [photos, setPhotos] = useState([]);
	const [loading, setLoading] = useState(true);

	const printRef = useRef();

	const pending = useMemo(() => isAssetPending(asset_status), [asset_status]);

	const showActions = useMemo(
		() => userIsActive && !readonly,
		[readonly, userIsActive],
	);

	const assetCards = useMemo(() => {
		const type = ASSET_TYPES_REVERSE[asset_type];
		return toTasksCardEntity(entity, type, true);
	}, [entity, asset_type]);

	const isAssetDeletable = useMemo(
		() =>
			(!properties?.liquid?.enable ||
				!properties?.liquid?.level?.enable ||
				properties?.liquid?.level?.bl === 0) &&
			[ASSET_TYPES.cask, ASSET_TYPES.ibc, ASSET_TYPES.pallet].includes(
				asset_type,
			),
		[
			asset_type,
			properties?.liquid?.enable,
			properties?.liquid?.level?.bl,
			properties?.liquid?.level?.enable,
		],
	);

	const isAssetDefected = useMemo(
		() => asset_status === ASSET_STATUS.DEFECTED,
		[asset_status],
	);

	const isAssetDefectable = useMemo(
		() =>
			!isAssetDefected &&
			[
				ASSET_TYPES.cask,
				ASSET_TYPES.ibc,
				ASSET_TYPES.pallet,
				ASSET_TYPES.container,
				ASSET_TYPES.filling_tank,
			].includes(asset_type),
		[asset_type, isAssetDefected],
	);

	const users = useCompanyUsers();

	// console.log('isAssetDeletable ', isAssetDeletable, ' properties ', properties);

	const handlePrint = (ref) => {
		const element = ref?.current?.innerHTML;
		printElement({ element, styles: 'body {padding: 0; margin: 0;}' });
	};

	const handleNew = () => {
		const route = ASSET_EDIT_URL[asset_type];
		console.log('Route', asset_type, route);
		navigate(`${route}`, { replace: true });
	};

	const handleClone = () => {
		const route = ASSET_EDIT_URL[asset_type];
		const str = JSON.stringify({ ...entity, history: null });
		const base = Buffer.from(str).toString('base64');
		navigate(`${route}?clone=${base}`, { replace: true });
	};

	const handleDelete = async () => {
		dispatch(deleteAssetThunk(path))
			.unwrap()
			.then(() => {
				const route = ASSET_EDIT_URL?.[asset_type];
				navigate(`${route}/new`);
			})
			.finally(() => {
				setDeleteModal(false);
			});
	};

	const handleRegauge = () => {
		navigate(`/app/regauge?asset=${path}`);
	};

	const handleSample = () => {
		navigate(`/app/sample?asset=${path}`);
	};

	const handleReportDefect = () => {
		navigate(`/app/report-defect?asset=${path}`);
	};

	const handleAction = (key) => setActionKey(key);

	const onCloseModal = useCallback(
		(forceRefresh) => {
			if (forceRefresh) {
				refresh(path ?? asset_id);
			}
			setActionKey(null);
		},
		[refresh, path, asset_id],
	);

	const getAssetPhotos = async () => {
		try {
			setLoading(true);
			const res = await getAssetPhoto({ assetId: asset_uid });
			setPhotos(res.images);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const deletePhoto = async (id) => {
		setLoading(true);
		try {
			await deleteAssetPhoto({
				assetId: asset_uid,
				imageId: id,
			});
			setPhotos((prevItems) => prevItems.filter((item) => item.path !== id));
			enqueueSnackbar(`Successfully deleted`, {
				variant: 'success',
			});
		} catch (error) {
			enqueueSnackbar(error?.message, {
				variant: 'error',
			});
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getAssetPhotos().finally();
	}, []);

	return (
		<Stack sx={styles} className='root' spacing={3}>
			{showControls && <H5>Asset</H5>}

			<Box className='summary-qr'>
				<Box ref={printRef} className='qr'>
					<AssetQRCode asset={entity} />
				</Box>

				{showActions && (
					<IconCircleButton
						onClick={() => handlePrint(printRef)}
						variant='outlined'
						color='secondary'
					>
						<Print height={24} width={24} />
					</IconCircleButton>
				)}
			</Box>

			{showActions && pending && canApprove && (
				<Box className='section'>
					<H5 className='section-header'>Approve Pending</H5>
					<Body1>
						This asset has pending changes which need to be approved.
					</Body1>
					<FlexBox justifyContent='flex-start'>
						<TitledButton
							handleClick={() => handleAction('approve_selected')}
							label='Approve'
						>
							<Done height={24} width={24} color='success' />
						</TitledButton>
						{history && (
							<TitledButton
								handleClick={() => handleAction('reject_selected')}
								label='Reject'
							>
								<Restore height={24} width={24} color='error' />
							</TitledButton>
						)}
					</FlexBox>
				</Box>
			)}

			{showActions && isAssetDefected && (
				<Stack className='section'>
					<H5 sx={{ color: 'red' }} className='section-header'>
						Asset Defective
					</H5>
					<Body1>
						{defect_note ?? 'This asset has been marked as defective.'}
					</Body1>
					{defects.map((d, i) => (
						<DefectItem index={i} item={d} key={`${i}`} />
					))}
				</Stack>
			)}

			{showControls && (
				<FlexBox justifyContent='center' sx={{ flexWrap: 'wrap' }}>
					{showActions && (
						<TitledButton
							handleClick={handleNew}
							label='New'
						>
							<AddCircle height={24} width={24} />
						</TitledButton>
					)}

					{showActions && ![ASSET_TYPES.filling_tank].includes(asset_type) && (
						<TitledButton
							handleClick={handleClone}
							label='Clone'
						>
							<CopyAllOutlined height={24} width={24} />
						</TitledButton>
					)}

					{showActions && (
						<CameraFormFieldComponent
							asset_uid={asset_uid}
							onRefetch={getAssetPhotos}
						/>
					)}

					{showActions && !isAssetDefected && isAssetDeletable && (
						<TitledButton
							handleClick={() => setDeleteModal(true)}
							label='Delete'
						>
							<Delete height={24} width={24} color='error' />
						</TitledButton>
					)}

					{showActions && isAssetDefectable && (
						<TitledButton
							handleClick={handleReportDefect}
							label='Defect'
						>
							<Error height={24} width={24} />
						</TitledButton>
					)}
				</FlexBox>
			)}

			{showActions &&
				!isAssetDefected &&
				[ASSET_TYPES.cask, ASSET_TYPES.ibc, ASSET_TYPES.filling_tank].includes(
					asset_type,
				) && (
					<FlexBox justifyContent='center' sx={{ flexWrap: 'wrap' }}>
						<TitledButton
							handleClick={handleRegauge}
							label='Regauge'
						>
							<Regauge height={24} width={24} />
						</TitledButton>
						<TitledButton
							handleClick={handleSample}
							label='Sample'
						>
							<Sample height={24} width={24} />
						</TitledButton>
					</FlexBox>
				)}

			{showActions && <CreatorProps entity={entity} />}

			{showActions && entity?.metrics && <SensorWidget data={entity.metrics} />}

			{canApprove && actionKey && (
				<TaskModal
					users={users}
					tasks={[assetCards]}
					onClose={onCloseModal}
					actionKey={actionKey}
				/>
			)}

			{showActions && deleteModal && (
				<Confirm
					open={deleteModal}
					onConfirm={handleDelete}
					onCancel={() => setDeleteModal(false)}
				>
					<Body1>Deleting the asset will remove it permanently.</Body1>
				</Confirm>
			)}

			{photos?.length > 0 && (
				<Box className='section'>
					<H5 className='section-header'>Asset Images</H5>

					<Box className='inventory'>
						<Box
							className={`photos-container ${photos?.length > 0 ? 'has-photo' : null}`}
						>
							<Box className='photos'>
								{(photos ?? []).map((photo, index) => (
									<Box className='photo-item' key={index}>
										<img
											key={index}
											src={photo.dataUrl}
											alt={`Photo ${index + 1}`}
											className='photo-style'
										/>
										<div className='delete'>
											{!loading && (
												<ButtonBase onClick={() => deletePhoto(photo.path)}>
													<DeleteForever />
												</ButtonBase>
											)}
										</div>
									</Box>
								))}
								{loading && (
									<CircularProgress
										size='10%'
										thickness={3.5}
										sx={{ opacity: '.65' }}
									/>
								)}
							</Box>
						</Box>
					</Box>
				</Box>
			)}
			<AssetLog {...{ path, asset_status, update_on }} />
		</Stack>
	);
}

export default withSmallDialogCard(AssetSummary);
