import { Close } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import { BasicMenu } from '@pw/components/Administration/components/BasicMenu';
import FilledButton from '@pw/components/Buttons/FilledButton';
import IconCircleButton from '@pw/components/Buttons/IconCircleButton';
import HeadlineWidgetChart from '@pw/components/Dashboard/components/HeadlineWidgetChart';
import LiquidTransfersWidget from '@pw/components/Dashboard/components/LiquidTransfersWidget';
import { FormikDatePicker, FormikForm, FormikSelect } from '@pw/components/Forms/FormikForm';
import Filter from '@pw/components/icons/Filter';
import Liquid from '@pw/components/icons/Liquid';
import { Body3, H5 } from '@pw/components/Typography';
import Bolder from '@pw/components/Typography/Bolder';
import { UNIT } from '@pw/consts/units';
import { useCompanyLiquidTypes } from '@pw/redux/containers/User';
import useConverter from '@pw/utilities/hooks/logic/useConverter';
import { useAssetsOverviewLazyQuery } from '@pw/utilities/hooks/service/useAssetsOverviewQuery';
import { useLiquidOverviewLazyQuery } from '@pw/utilities/hooks/service/useLiquidOverviewQuery';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

function LiquidSection({}) {
  const { t } = useTranslation();
  const converter = useConverter();
  const bl_unit = converter.unit(UNIT.BL);
  const la_unit = converter.unit(UNIT.LA);

  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState(null);

  const [fetchLiquidData, { data: liquidData, isLoading }] = useLiquidOverviewLazyQuery();

  const liquidTypes = useCompanyLiquidTypes();
  const liquidOptions = useMemo(() => liquidTypes.map((s) => ({ label: s.sku_name, value: s.sku_id })), [liquidTypes]);

  const changeSet = useMemo(() => ({
    start_date: [filter?.start_date ?? new Date(0).getTime(), yup.number().nullable()],
    end_date: [filter?.end_date ?? new Date().getTime(), yup.number().nullable()],
    sku_id: [filter?.sku_id, yup.string().nullable()],
  }), [filter]);

  useEffect(() => {
    fetchLiquidData({}).finally(() => {});
  }, [fetchLiquidData]);

  const onFilter = (values) => {
    console.log('Applying filter', values);
    fetchLiquidData(values).finally(() => setFilter(values));
  };

  const parsed = (liquidData ?? []).reduce(
    (acc, item) => {
      acc.total_bl += converter.from(item?.total_bl ?? 0, UNIT.BL);
      acc.total_la += converter.from(item?.total_la ?? 0, UNIT.LA);

      acc.total_bl_produced += converter.from(
        item?.total_bl_produced ?? 0,
        UNIT.BL,
      );
      acc.total_la_produced += converter.from(
        item?.total_la_produced ?? 0,
        UNIT.LA,
      );

      acc.bl.push(converter.from(item?.total_bl ?? 0, UNIT.BL));
      acc.la.push(converter.from(item?.total_la ?? 0, UNIT.LA));

      acc.produced_bl.push(
        converter.from(item.total_bl_produced ?? 0, UNIT.BL),
      );
      acc.produced_la.push(
        converter.from(item.total_la_produced ?? 0, UNIT.LA),
      );

      acc.total_bl_in += Math.abs(
        converter.from(item?.total_bl_in ?? 0, UNIT.BL),
      );
      acc.total_la_in += Math.abs(
        converter.from(item?.total_la_in ?? 0, UNIT.LA),
      );

      acc.total_bl_out += Math.abs(
        converter.from(item?.total_bl_out ?? 0, UNIT.BL),
      );
      acc.total_la_out += Math.abs(
        converter.from(item?.total_la_out ?? 0, UNIT.LA),
      );

      acc.dates.push(new Date(item.binned_timestamp));
      return acc;
    },
    {
      bl: [],
      la: [],
      produced_bl: [],
      produced_la: [],
      dates: [],
      total_bl: 0,
      total_la: 0,
      total_bl_produced: 0,
      total_la_produced: 0,
      total_bl_in: 0,
      total_la_in: 0,
      total_bl_out: 0,
      total_la_out: 0,
    },
  );

  return (
    <Box className='content-row'>
      <Box className='content-header'>
        <Box className='title-box'>
          <Liquid height={24} width={24} />
          <H5>Liquid</H5>
          {filter && (
            <>
              <Body3 sx={{ alignItems: 'center' }}>
                {filter?.start_date && (
                  <>
                    <Bolder style={{ paddingLeft: '0.25ch', fontWeight: '500' }}>
                      fr.
                    </Bolder>
                    {new Date(filter?.start_date).toLocaleDateString()}
                  </>
                )}
                {filter?.end_date && (
                  <>
                    <Bolder style={{ paddingLeft: '0.25ch', fontWeight: '500' }}>
                      to.
                    </Bolder>
                    {new Date(filter?.end_date).toLocaleDateString()}
                  </>
                )}
              </Body3>
              {filter?.sku_id && (
                <Body3>{liquidTypes.find((s) => s.sku_id === filter?.sku_id)?.sku_name}</Body3>
              )}
            </>
          )}
        </Box>

        <IconCircleButton onClick={() => setShowFilter((v) => !v)}>
          {showFilter ? (
            <Close width='1em' height='1em' sx={{ color: '#898989' }} />
          ) : (
            <Filter width='1em' height='1em' fill='#898989' />
          )}
        </IconCircleButton>
      </Box>

      <Collapse in={showFilter} unmountOnExit>
        <FormikForm changeSet={changeSet} onSubmit={onFilter}>
          <Stack gap={1.5} pt={2} pl={1.5} pr={2}>
            <Box display='flex' flexBasis='row' gap={1}>
              <FormikDatePicker name='start_date' label='From' fullWidth />
              <FormikDatePicker name='end_date' label='To' fullWidth />
              <FormikSelect name='sku_id' label='Liquid' options={liquidOptions} fullWidth />
            </Box>
            <Box display='flex' justifyContent='flex-end'>
              <FilledButton
                sx={{ maxWidth: 'fit-content' }}
                type='submit'
                size='small'
                label='Apply'
                loading={isLoading}
              />
            </Box>
          </Stack>
        </FormikForm>
      </Collapse>

      <Box className='content-body content-3col'>
        <HeadlineWidgetChart
          title='Total Liquid'
          data={[
            {
              id: 0,
              label: `${t('bulk')}(${bl_unit})`,
              value: parsed.total_bl ?? 0,
              color: '#41424c',
            },
            {
              id: 1,
              label: `${t('alcohol')}(${la_unit})`,
              value: parsed.total_la ?? 0,
              color: '#F0AA29',
            },
          ]}
          lineChartData={{
            bl: parsed.bl,
            la: parsed.la,
            dates: parsed.dates,
          }}
        />
        <HeadlineWidgetChart
          title='Total Production'
          data={[
            {
              id: 0,
              label: `${t('bulk')}(${bl_unit})`,
              value: parsed.total_bl_produced ?? 0,
              color: '#41424c',
            },
            {
              id: 1,
              label: `${t('alcohol')}(${la_unit})`,
              value: parsed.total_la_produced ?? 0,
              color: '#F0AA29',
            },
          ]}
          lineChartData={{
            bl: parsed.produced_bl,
            la: parsed.produced_la,
            dates: parsed.dates,
          }}
        />
        <LiquidTransfersWidget
          title='Liquid Movement'
          bl_in={parsed.total_bl_in}
          la_in={parsed.total_la_in}
          bl_out={parsed.total_bl_out}
          la_out={parsed.total_la_out}
        />
      </Box>
    </Box>
  );
}

export default LiquidSection;
